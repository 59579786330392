body {
    font-family: 'Montserrat', sans-serif !important;
    background-color: #fefefe;    
}

.navbar-shadow-less {
    box-shadow: none !important;
    border-bottom: solid 1px #dedede !important;
}

.default-bg-color {
    background-color: #0B758C !important;
}

.card-border_custom {
    border-radius: 15px;
    border: solid 1px #dedede !important;
    cursor: pointer !important;
}

.home-header {
    padding-top: 2rem;
    padding-bottom: 1.3rem;
}

.home-header h1 {
    font-size: 1.7rem;
    font-weight: 600;
    border-left: 2px;
    border-left: solid #0B758C 0.4rem;
    padding-left: 0.5rem !important;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.home-header h6 {
    font-weight: 400;
    margin-top: 1rem;
}

.logo {
    font-weight: 700;
    color: #fefefe;
}

.numero-apolice {
    display: flex;
    font-size: 0.8rem;  
    flex-direction: column; 
}

.numero-apolice span {
    display: block;
    font-size: 2rem;
}

.descricao-apolice {
    margin: 1rem 0;
    display: flex;
    font-size: 0.7rem;  
    flex-direction: column; 
    font-weight: 500;
}

.descricao-apolice span {
    margin-bottom: 0.5rem;
    display: block;
    font-size: 1rem;
    font-weight: 600;
}

.status-card.list-type{
    display: inline-block;
    padding: 0.5rem 2rem;
    border-radius: 35px;
}

.result-number {
    font-size: 0.9rem;
}

.text-right{
    text-align: right;
}

.update-time {
    font-size: 0.9rem;
}

.status-card {
    border-radius: 7px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 2rem;
    font-size: 0.9rem;
    padding: 0.3rem 0;
    text-transform: uppercase;
    color: #242424;
}

.status-card.vigente {
    background-color: #50b6f0;
    color: #fefefe !important
}

.status-card.pendente {
    background-color: #3e9f39;
    color: #fefefe !important
}

.status-card.atrasada {
    background-color: #fb4747;
    color: #fefefe !important
}

.status-card .status-label{
    display: block;
    font-size: 0.65rem !important;
    text-transform: uppercase;
    

}

.navbar-default {
    padding: 0 !important;
}

.card-custom {
    background-color: #fefefe;
}

.footer {
    border: solid #dedede 1px;
    bottom: 0;
    padding-top: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 1rem;
}

.border_custom {
    border: solid 1px #dedede !important;
}

.btn-default_custom {
    text-transform: initial;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.95rem;
    margin: 0.4rem 0.2rem;
    color: #0B758C !important;
}

.btn-default_custom i {
    font-size: 1.5rem;
}

.btn-default_custom.filter{
    margin: 0 1rem !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
}

.border-rounded_custom_50 {
    border-radius: 50% !important;
}

.badge_custom {
    display: inline-flex;
    background-color: #f7f7f7;
    border: 1px solid #dedede;
    padding: 0.5rem 1rem;
    margin: 1rem 0.5rem;    
    border-radius: 7px;
    align-items: center;
}

.badge_custom button {
    margin-left: 0.1rem;
    border: none;
    background-color: transparent;
}

.badge_custom .filter-type{
    display: block;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.badge_custom .filter-value{
    display: inline-block;
    font-size: 1rem;
    margin: 0;
    padding: 0;
}

.badge_custom i {
    font-size: 1.5rem;
}

.badge_custom.filter {
    border-radius: 30px !important;
}

.form-component {
    display: flex;
    flex-direction: column;    
}

.form-component label {
    font-weight: 600;
    font-size: 0.8rem;
}

.form-component select {
    margin-top: 0.5rem;
    border: solid 1px #dedede;
    padding: 0.3rem 0.5rem; 
    border-radius: 5px;
    color: #464646;
}

.form-component input {
    margin-top: 0.5rem;
    border: solid 1px #dedede;
    padding: 0.25rem 0.5rem; 
    border-radius: 5px;
    font-size: 0.9rem;
}

.form-component input:focus{
    outline: none;
}

.form-component select:focus{
    outline: none;
}

.form-component input:disabled {
    border: none;
}

.form-component .filterValue_disabled{
    color: #7f7f7f !important;
}

.icon-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px !important;
    height: 50px !important;
    background-color: #0B758C;
    color: #f7f7f7;
    border-radius: 50%;
    font-size: 1.7rem;
    font-weight: initial;
}

.section-title {
    font-weight: 600;
}

.section-desc {
    margin-top: 1.3rem;
    font-weight: 600;
    font-size: 0.9rem;
}

.sort-by-btn {
    background-color: #fefefe;
    border: 1px solid #dedede;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    align-items: center;
    color: #0B758C;
    text-transform: initial;
    box-shadow: none !important;
    font-weight: 600;
    font-size: 0.8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.sort-by-btn i{
    font-size: 1.2rem;
}

.sort-by-btn:focus{
    color: #fefefe;
    background-color: #0B758C;
    border: none;
}

.sort-by-btn:hover{
    color: #fefefe !important;
    background-color: #0B758C !important;
    border: none;
}

.sort-container {
    right: 0 !important;
}

.settings-area{
    min-height: 71.9vh !important;
    margin-top: 3rem;
}

.list-settings span {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.list-settings i {
    font-size: 1.4rem;
}

.btn-change-view {
    font-size: 2rem !important;    
}

.list-view_custom span {
    font-weight: 500;
    font-size: 0.8rem;
}

.tabs-conexoes {
    font-weight: 700 !important;
    font-size: 0.95rem !important;
    text-transform: initial !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabs-conexoes i {
    font-size: 2rem !important;
}

.conexoes-section-desc {
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 1rem;
}

.date-picker_custom {
    z-index: 100;
    position: absolute;
    
}

.description-label{
    font-weight: 600;
    font-size: 0.8rem;
}


.btn-invoice_filter {
    border-radius: 7px;
    margin: 0 0.3rem;
    background-color: #f7f7f7;
    border: #d6d6d6 solid 1px;
    font-weight: 600;
    text-transform: initial;
    font-size: 0.85rem;
}

.btn-invoice_filter:hover {
    background-color: #0B758C;
    color: #f7f7f7;
}

.btn-invoice_filter.active {
    border: none;
    background-color: #0B758C;
    color: #f7f7f7;
}

.icon-circle_line {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    padding: 10px 10px;
    border: solid 1px #0B758C;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.tip-text {
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 1rem;
}

.card-title_custom {
    font-weight: 600;
}

.cursor-pointer {
    cursor: pointer !important;
}

.border-radius-50 {
    border-radius: 50% !important;
    padding: 5px 5px;
    font-size: 1rem;
}

.terms-viewer{
    position: relative;
    height: 500px;
    overflow-y: scroll;
}

.terms-viewer p{
    white-space: pre-wrap !important;
    text-align: justify;
}

.icon-notification {
    font-size: 1.5rem;
    padding: 10px;
    display: inline-flex;
    background-color: #047B99;
}

.icon-notification i {
    color: #f7f7f7 !important;
}

.icon-trash-notification {
    font-size: 1.5rem;
    padding: 10px;
    display: inline-flex;
    background-color: transparent;
}

.icon-trash-notification i{
    color: red !important;
}

.color-default {
    color: #047B99 !important;
}

.text-notification {
    font-size: 0.85rem;
    font-weight: 600;
    margin: 0 1rem;
    display: inline-block;
    width: 80%;
}

.date-notification {
    font-size: 0.8rem;
    font-weight: 600;
    background-color: #2f2f2f;
    border-radius: 10px;
    padding: 7px;
    color: #f7f7f7;
    margin-left: 0.5rem;
}

.notification-container{
    align-items: center !important;
    justify-content: space-between !important;
}

.tip-notifications {
    font-size: 0.8rem;
    font-weight: 600;
    color: #767676;
    padding: 1rem;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center !important;
}

.tip-notifications i {
    font-size: 1.25rem;
}

.border-radius-15 {
    border-radius: 15px !important;
}

.border-radius-10 {
    border-radius: 10px !important;
}

.container-height-100 {
    min-height: 100vh !important;
    padding: 2rem 0 !important;
}

.bg-login {
    position: relative;
    background-image: url('../img/background-login.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}

.bg-login::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom right, #047B99, #349279, #0B758C);
    opacity: 60%;
    z-index: 0;
}

.forgetPassword {
    margin-top: 0.7rem !important;
    font-size: 0.8rem !important;
    font-weight: 600;
}

.forgetPassword a {
    color: #0B758C !important;
}

.card-login {
    border-radius: 15px !important;
    padding: 0.7rem 2rem !important;
}

.btn-access-area {
    background-color: #0B758C;
    color: #f5f5f5;
    border-radius: 10px;
    text-transform: initial;
    font-size: 1rem;
    padding: 0.7rem 1rem;
    width: 100% !important;
}

.btn-access-area-outline {
    background-color: transparent;
    color: #0B758C;
    border: 1px solid #0B758C !important;
    border-radius: 10px;
    text-transform: initial;
    font-size: 1rem;
    padding: 0.7rem 1rem;
    width: 100% !important;
}

.btn-access-area-outline:hover {
    background-color: #529439 !important;
}

.btn-access-area-outline:focus {
    background-color: #529439 !important;
}

.btn-access-area-outline:active {
    background-color: #529439 !important;
}

.btn-access-area-outline:disabled {
    background-color: rgb(125, 154, 114);
}

.btn-access-area:hover {
    background-color: #529439 !important;
}

.btn-access-area:focus {
    background-color: #529439 !important;
}

.btn-access-area:active {
    background-color: #529439 !important;
}

.btn-access-area:disabled {
    background-color: rgb(125, 154, 114);
}

.input-desc {
    font-weight: 600;
    font-size: 0.85rem;
}

.text-access-desc {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    font-weight: 500;
}

.login-title {
    text-align: center;
    font-size: 1.5rem;
}

.register-account {
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 2rem;
}

.register-account a{
    display: inline-block;
    color: #0B758C;
    padding-bottom: 0.1rem;
    border-bottom: dotted 2px #0B758C;
}

.register-account a:hover{
    color: #4d8c36;
}


.register-account button{
    display: inline-block;
    color: #0B758C;
    padding: 0;
    padding-bottom: 0.1rem;
    border: none;
    font-weight: 600;
    border-bottom: dotted 2px #0B758C;
    background-color: transparent;
}

.register-account button:hover{
    color: #4d8c36;
}

.hint-access {
    font-size: 0.75rem;
    font-weight: 600;
    color: rgb(244, 55, 55);
    display: block;
}

.account-terms {
    font-size: 0.7rem !important;
}

.text-justify {
    text-align: justify !important;
}

.btn-vinculo {
    font-size: 0.9rem !important;
}

.hint-login-ff-text {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 0.9rem;
}

.hint-complementary {
    font-weight: 700;
    font-size: 0.9rem;
}

.hint-code {
    font-weight: 600;
    font-size: 0.8rem;
}

.hint-complementary-final {
    font-size: 0.8rem;
    font-weight: 600;
    text-align: left;
}

.select-categoria-campanha {
    width: 100% !important;
}

.select-categoria-campanha select:disabled {
    background-color: #eeeeee !important;
}

.user-cat-participacao {
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem 1rem;
    text-align: center;
}

.user-cat-participacao span{
    display: inline-block;
    margin-top: 1rem;
    background-color: #0B758C;
    border-radius: 30px;
    padding: 0.5rem 1rem;
    color: #f7f7f7;
}

.show-on-responsive {
    display: none;
}

/*CONFIGURAÇÃO DE RESPONSIVIDADE*/

@media (max-width: 768px) {
    .hidden-on-responsive {
        display: none;
    }

    .show-on-responsive {
        display: initial;
    }

    .margin-top-1 {
        margin-top: 1rem;
    }

    .filter-used-container {
        margin-top: 1rem;
        overflow-x: scroll;
        display: flex;
    }
}
